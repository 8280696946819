import React, { Component } from "react"
import "./navbar.styles.scss"
import { Link } from "gatsby"

class NavBar extends Component {
  state = {
    opacity: "1",
    transition: "0",
  }

  listenScrollEvent = e => {
    if (window.scrollY > 200) {
      this.setState({
        opacity: "0.9",
        transition: "background 0.6s ease-out",
      })
    } else {
      this.setState({
        transition: "0.6s",
        opacity: "1",
      })
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent)
  }

  render() {
    return (
      <div
        className="pages-link"
        style={{
          transition: this.state.transition,
          opacity: this.state.opacity,
        }}
      >
        <Link to="/">
          <h3 className="name">William Cheshier</h3>
        </Link>
        <div className="menu-nav-links">
          <Link className="desktop-nav-links" to="/">
            Home
          </Link>
          <Link className="desktop-nav-links" to="/about">
            About
          </Link>
          <Link className="desktop-nav-links" to="/portfolio">
            Portfolio
          </Link>
          <Link className="desktop-nav-links" to="/contact">
            Contact
          </Link>
        </div>
      </div>
    )
  }
}

export default NavBar
