import React from "react"
import "./footer.styles.scss"

const Footer = () => {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
        crossorigin="anonymous"
      />
      <div className="footer">
        <p>William Cheshier © - 2020 </p>
        <div className="footer-icons">
          <a href="https://github.com/wchesh24" target="_blank">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://twitter.com/TheChesh24" target="_blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/william-cheshier-a23b7595/"
            target="_blank"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href="https://www.facebook.com/will.cheshier.7?ref=bookmarks"
            target="_blank"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/thechesh24/" target="_blank">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
