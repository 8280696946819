import React, { useState } from "react"
import "./navbar.styles.scss"
import { Link } from "gatsby"

function MobileNavBar() {
  const [menuOpen, toggleMenuOpen] = useState(false)

  const styles = {
    menuLinks: {
      display: menuOpen ? "flex" : "none",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "#43cea2",
      position: "absolute",
      zIndex: "1005",
      margin: "0 auto",
      top: "65px",
      // opacity: "0.9",
      right: "0",
      height: "100vh",
      width: "100%",

      ul: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "0px",
        listStyle: "none",

        li: {
          margin: " 8px auto",
        },
      },
    },
    menuIcon: {
      cursor: "pointer",
      background: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      height: "2rem",
      outline: "thin",
      zIndex: "1111",
      navDiv: {
        width: "2rem",
        height: "0.25rem",
        border: "0.5px solid black",
        background: "black",
        background: menuOpen ? "white" : "black",
        borderRadius: "10px",
        transformOrigin: "1px",
        transition: "opacity 300ms, transform 300ms",
        firstChild: {
          transform: menuOpen ? "rotate(45deg)" : "rotate(0)",
        },
        secondChild: {
          opacity: menuOpen ? "0" : "1",
          transform: menuOpen ? "translateX(-20px)" : "translateX(0)",
        },
        thirdChild: {
          transform: menuOpen ? "rotate(-45deg)" : "rotate(0)",
        },
      },
    },
  }
  return (
    <div className="mobile-pages-link">
      <Link to="/">
        <h3 className="name" style={{ color: "#000" }}>
          William Cheshier
        </h3>
      </Link>
      <div className="menu-icon-container">
        <button
          style={styles.menuIcon}
          menuOpen={menuOpen}
          onClick={() => toggleMenuOpen(!menuOpen)}
        >
          <div style={styles.menuIcon.navDiv} />
          <div style={styles.menuIcon.navDiv} />
          <div style={styles.menuIcon.navDiv} />
        </button>
      </div>
      <nav style={styles.menuLinks} className="fadeinDown" menuOpen={menuOpen}>
        <ul style={styles.menuLinks.ul}>
          <li style={styles.menuLinks.li}>
            <Link className="mobile-style-link" to="/">
              Home
            </Link>
          </li>
          <br />
          <li style={styles.menuLinks.li}>
            <Link className="mobile-style-link" to="/about">
              About
            </Link>
          </li>
          <br />
          <li style={styles.menuLinks.li}>
            <Link className="mobile-style-link" to="/portfolio">
              Portfolio
            </Link>
          </li>
          <br />
          <li style={styles.menuLinks.li}>
            <Link className="mobile-style-link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default MobileNavBar
